import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import NotoSansDevanagariRegular from './NotoSansDevanagari-Regular.ttf'; // Adjust the path as needed
import NotoSansDevanagariBold from './NotoSansDevanagari-Bold.ttf'; // Adjust the path as needed

// Register the fonts with react-pdf
Font.register({
  family: 'NotoSansDevanagari',
  fonts: [
    { src: NotoSansDevanagariRegular, fontWeight: 'normal' },
    { src: NotoSansDevanagariBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'NotoSansDevanagari',
    position: 'relative',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    opacity: 1.0, // Adjust opacity to make sure text is readable
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 80,
    paddingTop: 10,
  },
  header: {
    fontSize: 17,
    textAlign: 'center',
    marginBottom: 0,
    textDecorationColor: 'red',
    fontWeight: 'bold',
  },
  sectionHeader: {
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 1,
    marginBottom: 1,
    color: '#ff7300',
  },
  line: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  label: {
    fontSize: 15,
    width: '26%',
  },
  value: {
    fontSize: 15,
    width: '70%',
    marginLeft: '10px'
  },
  colon: {
    fontSize: 15,
    marginRight: 3, // Adjust the spacing as needed
  },
  additionalImageContainer: {
    position: 'absolute',
    top: 150,
    right: 45,
    width: 180, // Adjust the size as needed
    height: 180,
    justifyContent: 'center',
    alignItems: 'center',
  },
  additionalImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius:10
  },
  imagePreviewContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 3,
    marginTop: 25,
  },
  imagePreview: {
    width: 60, // Adjust the size as needed
    height: 60,
  },
});

// Dynamic styles for additionalImageContainer with border conditionally applied


const PDFDocument = ({ formData, templateId, additionalImage, imagePreview, centerText }) => {
  const getBackgroundImage = () => {
    switch (templateId) {
      case "1":
        return {
          src: "Marriage Biodata Template-01.png",
          style: { top: 180, right: 50, borderWidth: 2, borderColor: '#ba7714', borderRadius:10 },
        };
      case "2":
        return {
          src: "Marriage Biodata Template-02.png",
          style: { top: 180, right: 50, borderWidth: 2, borderColor: '#a15740', borderRadius:10 },
        };
      case "3":
        return {
          src: "Marriage Biodata Template-03.png",
          style: { top: 180, right: 50, borderWidth: 2, borderColor: '#cebfac', borderRadius:10 },
        };
      case "4":
        return {
          src: "Marriage Biodata Template-04.png",
          style: { top: 180, right: 52, borderWidth: 2, borderColor: '#e37304', borderRadius:10 },
        };
      case "5":
        return {
          src: "Marriage Biodata Template-05.png",
          style: { top: 180, right: 40, borderWidth: 2, borderColor: '#ff6666', borderRadius:10 },
        };
        case "6":
        return {
          src: "Marriage Biodata Template-06.png",
          style: { top: 180, right: 40, borderWidth: 2, borderColor: '#ffc032', borderRadius:10 },
        };
        case "7":
        return {
          src: "Marriage Biodata Template-07.png",
          style: { top: 185, right: 60, borderWidth: 2, borderColor: '#e7bf4c' , borderRadius:10},
        };
        case "8":
        return {
          src: "Marriage Biodata Template-08.png",
          style: { top: 180, right: 40, borderWidth: 2, borderColor: '#015249', borderRadius:10 },
        };
        case "9":
        return {
          src: "Marriage Biodata Template-09.png",
          style: { top: 185, right: 52, borderWidth: 2, borderColor: '#657340', borderRadius:10 },
        };
        case "10":
        return {
          src: "Marriage Biodata Template-10.png",
          style: { top: 180, right: 40, borderWidth: 2, borderColor: '#ba7b2c', borderRadius:10 },
        };
        case "11":
        return {
          src: "Marriage Biodata Template-11.png",
          style: { top: 210, right: 40, borderWidth: 2, borderColor: '#e8bcc0', borderRadius:10 },
        };
        case "12":
        return {
          src: "Marriage Biodata Template-12.png",
          style: { top: 180, right: 40, borderWidth: 2, borderColor: '#c682aa', borderRadius:10 },
        };
      // Add cases for other templates...
      default:
        return {
          src: "Marriage Biodata Template-01.png",
          style: { top: 180, right: 50, borderWidth: 2, borderColor: '#ba7714', borderRadius:10 },
        };
    }
  };


  const formatDate = (dateObj) => {
    if (!dateObj) return '';
    const { day, month, year, varr } = dateObj;
    return `${varr} ${day}-${month}-${year}`;
  };

  const formatTime = (timeObj) => {
    if (!timeObj) return '';
    const { hour, minute, period } = timeObj;
    return `${period} ${hour} वा. ${minute} मि.`;
  };

  const formatValue = (value, key) => {
    switch (key) {
      case 'जन्मतारीख':
        return formatDate(value);
      case 'जन्मवेळ':
        return formatTime(value);
      case 'बहीण':
      case 'भाऊ':
      case 'मामा':
      case 'चूलते':
      case 'दाजी':
        return value.some(item => item.value) ? value.map(item => item.value).join(', ') : '';
      default:
        return value && value.value ? value.value : '';
    }
  };

  const formatLabel = (label) => {
    return label.replace(/_/g, ' ');
  };
  const { src: backgroundImage, style: additionalImageStyle } = getBackgroundImage();
  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.imageContainer}>
        {/* Background Image */}
        <Image style={styles.backgroundImage} src={backgroundImage} />

        <View style={styles.content}>
          {/* Image Preview */}
          <View style={styles.imagePreviewContainer}>
            <Image style={styles.imagePreview} src={imagePreview} />
          </View>
          <Text style={styles.sectionHeader}>{centerText}</Text>
          <Text style={styles.sectionHeader}>बायोडाटा</Text>

          {/* Additional Image with Dynamic Styling */}
          {additionalImage && (
            <View style={{ ...styles.additionalImageContainer, ...additionalImageStyle }}>
              <Image style={styles.additionalImage} src={additionalImage} />
            </View>
          )}

          {/* Form Data Rendering */}
          {Object.entries(formData).map(([key, value]) =>
            !['वडिलांचे_नाव', 'वडिलांचा_व्यवसाय', 'आईचे_नाव', 'बहीण', 'भाऊ', 'मामा', 'चूलते', 'दाजी', 'पत्ता', 'मोबाईल_नं', 'नातेसंबंध'].includes(key) && formatValue(value, key) ? (
              <View key={key} style={styles.line}>
                <Text style={styles.label}>{formatLabel(value.title || key)}</Text>
                <Text style={styles.colon}>: </Text>
                <Text style={styles.value}>{formatValue(value, key)}</Text>
              </View>
            ) : null
          )}

          {/* Family Information */}
          <Text style={styles.sectionHeader}>कौटुंबिक माहिती</Text>
          {Object.entries(formData).map(([key, value]) =>
            ['वडिलांचे_नाव', 'वडिलांचा_व्यवसाय', 'आईचे_नाव', 'बहीण', 'भाऊ', 'मामा', 'चूलते', 'दाजी', 'नातेसंबंध'].includes(key) && formatValue(value, key) ? (
              <View key={key} style={styles.line}>
                <Text style={styles.label}>{formatLabel(value.title || key)}</Text>
                <Text style={styles.colon}>: </Text>
                <Text style={styles.value}>{formatValue(value, key)}</Text>
              </View>
            ) : null
          )}

          {/* Contact Information */}
          <Text style={styles.sectionHeader}>संपर्क</Text>
          {Object.entries(formData).map(([key, value]) =>
            ['पत्ता', 'मोबाईल_नं'].includes(key) && formatValue(value, key) ? (
              <View key={key} style={styles.line}>
                <Text style={styles.label}>{formatLabel(value.title || key)}</Text>
                <Text style={styles.colon}>: </Text>
                <Text style={styles.value}>{formatValue(value, key)}</Text>
              </View>
            ) : null
          )}
        </View>
      </View>
    </Page>
  </Document>
  );
};

export default PDFDocument;
